export var bom = {
  value: 'bom',
  label: 'Basic Data',
  type: 'multiline',
  model: 'item',
  data: [],
  // tabDataStandard: [{
  //   label: 'Material',
  //   type: 'text',
  //   model: 'material',
  //   required: true,
  //   massKey: "MATERIAL"
  //   // disabled: true
  // },
  // {
  //   label: 'Plant',
  //   type: 'select',
  //   method: 'api',
  //   source: 'plant',
  //   model: 'plant',
  //   data: [],
  //   required: true,
  //   fieldValues: ['Werks', 'Name1'],
  //   takeValue: 'Werks',
  //   showValues: ['Werks', 'Name1'],
  //   size: 4,
  //   massKey: "PLANT"
  // },
  // {
  //   label: 'BOM Usage',
  //   type: 'select',
  //   method: 'api',
  //   source: 'bomUsageSet',
  //   model: 'bomUsage',
  //   data: [],
  //   required: true,
  //   fieldValues: ['Stlan', 'Antxt'],
  //   takeValue: 'Stlan',
  //   showValues: ['Stlan', 'Antxt'],
  //   size: 4,
  //   massKey: "BOM_USAGE"
  // }
  // ],
  // validations: [{
  //   fields: ['material', 'plant'],
  //   keys: ['Matnr', 'Werks'],
  //   source: 'materialInPlantValidation',
  //   model: 'BOM',
  //   onSelect: ['material', 'plant'],
  //   size: 4
  // },
  // {
  //   fields: ['material', 'plant', 'bomUsage'],
  //   keys: ['Matnr', 'Werks', 'Stlan'],
  //   source: 'altBomSet',
  //   model: 'BOM',
  //   onSelect: ['bomUsage'],
  //   size: 4
  // }],
  tabData: [
    {
      label: 'ICt',
      type: 'select',
      method: 'api',
      source: 'bomItemCategorySet',
      model: 'ict',
      data: [],
      fieldValues: ['Postp', 'Ptext'],
      takeValue: 'Postp',
      showValues: ['Postp', 'Ptext'],
      size: 4,
      required: true,
      massKey: "ITEM_CATEGORY"
    },
    {
      label: 'Component',
      type: 'text',
      model: 'component',
      reloadFields: ['uom','componentDesc'],
      reloadParam: 'Matnr',
      required: true,
      size: 4,
      massKey: "BOM_ITEMNO"
    },
    {
      label: 'Component Description',
      type: 'text',
      model: 'componentDesc',
      source: 'materialList',
      showValue: 'description',
      required: true,
      disabled: true,
      size: 4,
      massKey: "MATERIAL_ITEM"
    },
    {
      label: 'Quantity',
      type: 'number',
      model: 'quantity',
      required: true,
      size: 4,
      massKey: "QUANTITY_ITEM"
    },
    {
      label: 'Unit of Measure',
      type: 'text',
      model: 'uom',
      source: 'uomSet',
      showValue: 'Meins',
      required: true,
      // disabled: true,
      size: 4,
      massKey: "UOM_ITEM"
    },
    // {
    //   label: 'Unit of Measure',
    //   type: 'select',
    //   method: 'api',
    //   source: 'uomSet',
    //   model: 'uom',
    //   data: [],
    //   fieldValues: ['Meins'],////response of api 
    //   takeValue: 'Meins',///value which is to be passed
    //   showValues: ['Meins'], ///values which is to be show on screen
    //   size: 4,
    //   required: true,
    //   massKey: "UOM_ITEM",
    //   flagReload: true
    // }
  ],
  // tabDataNested: {
  //   type: 'multiline',
  //   model: 'subitems',
  //   text: 'Add Sub Items',
  //   data: {},
  //   tabData: [{
  //     label: 'SItem',
  //     type: 'select',
  //     method: 'api',
  //     source: 'bomItemCategorySet',
  //     model: 'subItem',
  //     data: [],
  //     fieldValues: ['Postp', 'Ptext'],
  //     takeValue: 'Postp',
  //     showValues: ['Postp', 'Ptext'],
  //     size: 3
  //   },
  //   {
  //     label: 'Sub Item Component',
  //     type: 'select',
  //     method: 'api',
  //     source: 'subItemComponent',
  //     model: 'ict',
  //     data: [],
  //     fieldValues: ['Postp', 'Ptext'],
  //     takeValue: 'Postp',
  //     showValues: ['Postp', 'Ptext'],
  //     size: 3
  //   },
  //   {
  //     label: 'Sub Item Text',
  //     type: 'text',
  //     model: 'subItemText',
  //     required: false,
  //     disable: true,
  //     size: 3
  //   },
  //   {
  //     label: 'Sub Item Quantity',
  //     type: 'number',
  //     model: 'subItemQuantity',
  //     required: false,
  //     size: 3
  //   }]
  // }
};
